@import "@sass/colors.scss";

.container {
  position: relative;
}

.searchCont {
  position: relative;

  & .searchLabel .searchSpan {
    z-index: 1000;
    position: absolute;
    top: 30%;
    left: 12px;
    color: black;
    transition: 0.3s all;
  }

  input[type="text"] {
    border-radius: 8px;
    width: 570px;
    height: 56px;
    font-size: 16px;
    font-weight: 600;
    padding-top: 20px;

    &:focus,
    &:not([value=""]) {
      & + .searchLabel .searchSpan {
        color: $grey;
        font-size: 14px;
        top: 5%;
      }
    }
  }
}

.searchInput {
  position: absolute;
  top: 33%;
  right: 4%;
  font-size: 20px;
  color: $green !important;
}

.deleteValue {
  visibility: visible;
  opacity: 1;
  color: $grey;
  cursor: pointer !important;
  transition: 0.3s all;
  font-size: 14px;

  position: absolute;
  top: 35%;
  right: 12%;
}

.hidden {
  visibility: hidden;
  opacity: 0;
}
