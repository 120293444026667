@import '@sass/colors.scss';

.button {
  background-color: $green !important;
  color: white !important;
  width: 100% !important;
  height: 65px !important;
  border-radius: 8px !important;
}

.select {
  min-width: 200px !important;
}