@import "@sass/colors.scss";

.filterRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}

.editIcon {
  margin-right: -100px;
  font-size: 30px;
  color: $green !important;
}

.row {
  &:nth-child(1n) {
    background-color: #f9fff9;
  }

  &:nth-child(2n) {
    background-color: transparent;
  }

  &:hover {
    td:last-child:after {
      position: absolute;
      right: 2%;
      display: inline;
      top: 17%;
      content: "";
      width: 2px;
      height: 70%;
      background-color: $green;
    }
  }
}

.trashIcon {
  font-size: 20px;
  color: red !important;
  cursor: pointer;
  margin-right: 20px;
}

.cancelButton {
  background-color: $button-bg-grey !important;
  color: $button-c-grey !important;
  margin-right: 10px;
}

.deleteButton {
  background-color: $button-bg-red !important;
  color: $button-c-red !important;

  &.deleteButtonDisabled {
    background-color: $button-bg-red-disabled !important;
    color: $button-c-red-disabled !important;
  }
}

@media screen and (max-width: 1400px) {
  .filterRow {
    flex-wrap: wrap;
    justify-content: unset;
  }
}

@media screen and (max-width: 900px) {
  .editIcon {
    margin-right: 0;
  }

  .searchInput {
    width: 300px !important;
  }
}

.red {
  color: $button-c-red !important;
}

.green {
  color: $green !important;
}

.delete {
  cursor: pointer;
}

.active {
  background-color: $light-green-table-row-delete !important;
}

.dropdown {
  border: none !important;
  color: $green !important;
  font-size: 17px !important;
  background: inherit !important;
  font-weight: 600 !important;
  box-shadow: none !important;
}