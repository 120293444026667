.ant-picker-cell-inner::before {
    border: 1px solid #1f9643e5 !important;
}

.ant-picker-cell-selected > div {
    background-color: #1f9643e5 !important;
}

.ant-picker-cell-range-start > div {
    background-color: #1f9643e5 !important;
}

.ant-picker-cell-range-end > div {
    background-color: #1f9643e5 !important;
}

/*.ant-picker-cell-in-range {*/
/*    background-color: #1f9643e5 !important;*/
/*}*/

.ant-picker-cell-today > div {
    background-color: rgba(112, 220, 145, 0.9) !important;
    color: white;
}

.ant-btn-primary {
    background-color: #3dcd58 !important;
    border-color: #1f9643e5 !important;
}

.ant-picker:hover {
    border-color: #1f9643e5 !important;
    box-shadow: #1beb4233 !important;
}

.ant-picker-focused {
    border-color: #1f9643e5 !important;
    box-shadow: #1beb4233 !important;
}

.ant-picker:hover .ant-picker-focused {
    box-shadow: #1beb4233 !important;
}

.ant-picker-range .ant-picker-active-bar {
    background: #23ab37 !important;
}