@import "@sass/colors.scss";

.editDeleteContainer {
    margin-left: -20px;
}

.deleteIcon {
    margin-left: 5px;
    font-size: 24px;
    color: $button-c-red !important;
}

.DeleteIcon:hover {
    cursor: pointer;
}

.editIcon {
    font-size: 24px;
    color: $green !important;
}

.editIcon:hover {
    cursor: pointer;
}

.activeStatus {
    color: $green;
}

.unActiveStatus {
    color: $button-c-red;
}

.lastVisit {
    margin-left: 30px !important;
}
