@import "@sass/colors.scss";

.button {
  background-color: transparent !important;
  color: $green !important;
  border: none !important;
  box-shadow: none !important;
  font-size: 16px !important;
  font-weight: 600 !important;
}

.trashIcon {
  font-size: 20px;
  color: red !important;
  cursor: pointer;
  margin-right: 20px;
}

.location {
  line-height: 17, 15px;
  color: $dark-gray;
}

.formButton {
  width: 100%;
  height: 65px !important;
  background-color: $green !important;
  font-size: 16px;
  color: white !important;
  border-radius: 8px !important;
}

.dragger {
  border-radius: 8px !important;
  border: 3px dashed $green !important;

  display: flex;
  justify-content: center;
  align-items: center;
}

.upload {
  width: 100% !important;
}

.cancelButton {
  background-color: $button-bg-grey !important;
  color: $button-c-grey !important;
  margin-right: 10px;
}

.deleteButton {
  background-color: $button-bg-red !important;
  color: $button-c-red !important;

  &.deleteButtonDisabled {
    background-color: $button-bg-red-disabled !important;
    color: $button-c-red-disabled !important;
  }
}

.disable_preview {
  position: absolute;
  top: 20px;
  right: 30px;

  svg {
    font-size: 28px;
    fill: $green;
  }
}

@media screen and (max-width: 900px) {
  .searchInput {
    width: 220px !important;
  }
}

.previewImg {
  border-radius: 8px !important;
  width: 100%;
  height: 380px;

  img {
    border-radius: 8px !important;
    display: block;
    margin: 0 auto;
    height: 100%;
    max-width: 100%;
  }

  .upperContainer {
    visibility: hidden;
  }

  &:hover .upperContainer {
    visibility: visible;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.233);
    width: 100%;
    height: 100%;
    border-radius: 8px;
  }
}

.previewText {
  width: 100%;
  height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: $green;
  font-weight: 600;
}

.fileInput {
  font-size: 16px;
  color: $green;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
}

.inputIcon {
  font-size: 20px;
  margin-right: 10px;
}

.previewDelete {
  cursor: pointer;

  position: absolute;
  top: 45%;
  left: 45%;
  font-size: 40px;
  color: red !important;
}

.filePreview {
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  margin-bottom: 25px;
  margin-top: 15px;

  & span {
    font-size: 16px;
  }

  & .fileDeleteIcon {
    font-size: 20px;
    color: red !important;
  }
}