@import '@sass/colors.scss';

.inputCont {
  position: relative;

  & label span {
    z-index: 1000;
    position: absolute;
    top: 20px;
    left: 12px;
    color: black;
    transition: 0.3s all;
  }

  textarea {
    border-radius: 8px;
    height: 65px;
    font-size: 16px;
    font-weight: 600;
    resize: none;
    padding-top: 26px;

    &:focus,
    &:not(:placeholder-shown) {
      & + label span {
        color: $grey;
        font-size: 14px;
        top: 10px;
      }
    }
  }
}