@import '@sass/colors.scss';

.button {
  background-color: transparent !important;
  color: $green !important;
  border: none !important;
  box-shadow: none !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  height: 44px;
}

.background {
  background-color: $light-green !important;
  border-radius: 8px;
}