@import "@sass/colors.scss";

.download_container {
  background-color: white;
  margin: 0 auto;
  height: 100vh;

  .link_container {
    padding-top: 50px;

    .download_link {
      margin: 0 auto;
      width: fit-content;

      a {
        padding: 10px;
        text-align: center;
        font-size: 18px;
        width: 300px;
        border-radius: 6px;
        border: 1px solid $green;
        background: $green;
        color: white;
        font-weight: 500;
      }
    }
  }
}