$green: #3DCD58;
$darkblue: #2E3A59;
$grey: #9FA0A4;


$light-green: #F3FFF2;
$light-green-table-row-delete: #dfffdc;

$button-bg-grey: #E8E8E8;
$button-c-grey: #A0A0A0;
$button-bg-red: #FFE1D8;
$button-bg-red-disabled: #ffe1d894;
$button-c-red: #FF5C28;
$button-c-red-disabled: #ff5e287a;

$dark-gray: #626469;