@import "@sass/colors.scss";

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    min-height: 56px;
    min-width: 100%;
    flex-direction: row-reverse;
}

.trashIcon {
    font-size: 20px;
    color: red !important;
    cursor: pointer;
    margin-right: 20px;
}
.cancelButton {
    background-color: $button-bg-grey !important;
    color: $button-c-grey !important;
    margin-right: 10px;
}

.deleteButton {
    background-color: $button-bg-red !important;
    color: $button-c-red !important;

    &.deleteButtonDisabled {
        background-color: $button-bg-red-disabled !important;
        color: $button-c-red-disabled !important;
    }
}

.passCont {
    justify-content: start;
    align-content: center;
    align-items: center;

    .eyeIcon {
        margin-left: 20px;
        margin-top: -4px;
        cursor: pointer;
        font-size: 18px;
    }
}

.editIcon {
    margin-right: -100px;
    font-size: 30px;
    color: $green !important;
}

.row {
    &:nth-child(1n) {
        background-color: #f9fff9;
    }

    &:nth-child(2n) {
        background-color: transparent;
    }

    &:hover {
        td:last-child:after {
            position: absolute;
            right: 2%;
            display: inline;
            top: 17%;
            content: "";
            width: 2px;
            height: 70%;
            background-color: $green;
        }
    }
}

.adminText {
    color: $green;
}

@media screen and (max-width: 1600px) {
    .editIcon {
        margin-right: 0px;
    }
}

@media screen and (max-width: 1400px) {
    .filterRow {
        flex-wrap: wrap;
        justify-content: unset;
    }
}

.searchBtn {
    background: $green !important;
    border: 1px solid $green !important;
    color: #ffffff !important;
}

.clearBtn {
    background: #ffffff !important;
    border: 1px solid $green !important;
    color: $green !important;
}

.searchInput {
    outline: none !important;

    &:focus {
        border: 1px solid $green !important;
        box-shadow: 0px 0px 2px $green !important;
    }

    &:hover {
        border: 1px solid $green !important;
        box-shadow: 0px 0px 3px $green !important;
    }
}
