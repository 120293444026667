@import "@sass/colors.scss";

.filterRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}

.passCont {
  justify-content: start;
  align-content: center;
  align-items: center;

  .eyeIcon {
    margin-left: 20px;
    margin-top: -4px;
    cursor: pointer;
    font-size: 18px;
  }
}

.editIcon {
  margin-right: -100px;
  font-size: 30px;
  color: $green !important;
}

.row {
  &:nth-child(1n) {
    background-color: #f9fff9;
  }

  &:nth-child(2n) {
    background-color: transparent;
  }

  &:hover {
    td:last-child:after {
      position: absolute;
      right: 2%;
      display: inline;
      top: 17%;
      content: "";
      width: 2px;
      height: 70%;
      background-color: $green;
    }
  }
}

.adminText {
  color: $green;
}

@media screen and (max-width: 1600px) {
  .editIcon {
    margin-right: 0px;
  }
}

@media screen and (max-width: 1400px) {
  .filterRow {
    flex-wrap: wrap;
    justify-content: unset;
  }
}