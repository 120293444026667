@import '@sass/colors.scss';

.container {
  padding: 40px;
}

.info {
  color: $dark-gray;
  line-height: 24px;
  font-size: 16px;
  max-width: 300px;
  width: 100%;
}

.card {
  width: 100%;
  border-radius: 16px !important;
  margin-bottom: 40px !important;
}

.button {
  padding: 0 !important;
}

.spin_color i {
  background: #3dcd58;
}

.range_picker {
  width: 300px;
  margin-bottom: 15px !important;
}
