@import "@sass/colors.scss";

.inputCont {
  position: relative;

  & label span {
    z-index: 1000;
    position: absolute;
    top: 36%;
    left: 12px;
    color: black;
    transition: 0.3s all;
  }

  input[type="password"] {
    border-radius: 8px;
    height: 65px;
    font-size: 16px;
    font-weight: 600;
    padding-top: 20px;

    &:focus,
    &:not(:placeholder-shown) {
      & + label span {
        color: $grey;
        font-size: 14px;
        top: 10px;
      }
    }
  }

  input:-webkit-autofill::first-line {
    font-family: "Avenir";
    font-weight: 600;
    font-size: 16px;
  }

  input[type="text"] {
    border-radius: 8px;
    height: 65px;
    font-weight: 600;
    font-size: 16px;
    padding-top: 20px;

    &:-webkit-autofill {
      border-radius: 8px;
      height: 65px;
      font-size: 16px !important;
      font-weight: 600 !important;
      padding-top: 20px;

      &:-webkit-autofill:focus,
      &:-webkit-autofill:not(:placeholder-shown) {
        & + label span {
          color: $grey;
          font-size: 14px;
          top: 10px;
        }
      }
    }

    &:focus,
    &:not(:placeholder-shown) {
      & + label span {
        color: $grey;
        font-size: 14px;
        top: 10px;
      }
    }
  }
}

.innput {
  width: fit-content;

  & label {
    cursor: pointer;
  }

  & input {
    display: none;
  }
}