@import "@sass/colors.scss";

.card {
  border-radius: 10px !important;
  margin-bottom: 40px !important;
  min-height: 220px !important;
  max-height: 272px !important;
  height: 100% !important;

  &.height_full {
    max-height: none !important;
  }

  & > div {
    padding: 0 !important;
  }

  .cardImg {
    max-height: 272px;
    max-width: 270px;
    width: 100%;
    border-radius: 10px;
  }

  .cardType {
    color: $grey;
  }

  .cardDescription {
    margin-top: 10px;
    font-size: 16px;
    font-weight: 500;
    max-width: 500px;
    margin-bottom: 40px;
  }

  .buttonRow {
    position: absolute;
    bottom: 30px;
    right: 0;
  }

  @media screen and (max-width: 1600px) {
    .buttonRow {
      bottom: 10px;
    }
  }
}

.active {
  &::after {
    display: block;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $green;
    opacity: 0.2;
    border-radius: 8px;
    pointer-events: none;
  }
}

.hover {
  cursor: pointer;
}


.descriptionButton {
  color: $green;
  cursor: pointer !important;
  margin-left: 10px;

}