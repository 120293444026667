@import "@sass/colors.scss";

.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
}

.fileInput {
  margin-bottom: 20px;
}