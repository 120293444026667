@import "@sass/colors.scss";

.selectContent {
  width: fit-content;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-wrap: nowrap;
  margin-right: 10px;

  span {
    font-size: 16px;
    font-weight: 600;
  }
}

.select {
  color: $green !important;
  font-size: 16px !important;
  font-weight: 600;
  margin-top: 1px !important;
  width: fit-content;
  min-width: 150px;
}

.c_option {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.defaultSelectContent {
  .label {
    position: absolute;
    top: 10px;
    left: 12px;
    color: $grey;
    z-index: 2;
  }

  & > div > div > span:nth-child(2n) {
    padding-top: 16px !important;
  }

  .defaultSelect > div {
    color: black !important;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    height: 65px !important;
    border-radius: 8px !important;
  }

}