@import "@sass/colors.scss";

.sider {
  max-width: 220px !important;
  min-width: 220px !important;
  width: 100% !important;
}

.line {
  border-bottom: 1px solid #ededed;
  width: 90%;
  position: absolute;
  right: 15px;
  padding-bottom: 5px;
}

.icon {
  color: black;
  font-size: 20px;

  &.active {
    color: $green;
  }
}

.spin {
  min-height: 100vh;
  background: #fff;
}

.authSpan {
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 19, 6px;
}

.menuItem {
  color: $darkblue;
  font-weight: 600;
  white-space: normal !important;
  line-height: 1.4 !important;
}

.authRightSide {
  background-color: $green;
}

.form {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.formItem {
  width: 70% !important;
}

.authLeftSide {
  display: flex !important;
  align-items: center;
}

.authLogo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  padding-top: 20%;
}

.button {
  width: 100%;
  background-color: $green !important;
  height: 61px !important;
  border-radius: 8px !important;
  border: none !important;
}