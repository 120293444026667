@import "@sass/colors.scss";

.select {
    min-width: 200px !important;
}

.text {
    font-size: 22px;
    text-align: center;
}
.label {
    padding-right: 10px;
    font-weight: 500;
    font-size: 15px;
}

.checkbox {
    background-color: white !important;
    border-color: white !important;
}

.button {
    width: 170% !important ;
    height: 55px !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    border-radius: 8px !important;
    border: none !important;
    background-color: $green !important;
    color: white !important;
    &__cancel {
        font-size: 16px !important;
        font-weight: 400 !important;
        border: none !important;
        color: rgba($color: #1ead21, $alpha: 1) !important;
        &__active {
            font-size: 16px !important;
            font-weight: 400 !important;
            border: none !important;
            color: rgba($color: #1ead21, $alpha: 1) !important;
        }
        &__disabled {
            font-size: 16px !important;
            font-weight: 400 !important;
            border: none !important;
            background: none !important;
            color: rgb(135, 135, 135) !important;
        }
    }
    &__disabled {
        width: 170% !important ;
        height: 55px !important;
        font-size: 16px !important;
        font-weight: 600 !important;
        border-radius: 8px !important;
        border: none !important;
        background-color: rgb(216, 218, 218) !important;
        color: rgb(135, 135, 135);
    }
}
