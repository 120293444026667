@import '@sass/colors.scss';

.button {
  background-color: $green !important;
  color: white !important;
  width: 100% !important;
  height: 65px !important;
  border-radius: 8px !important;
}

.switchContainer {
  & span {
    margin-left: 20px;
    font-size: 16px;
    font-weight: 600;
  }

  button[aria-checked=true] {
    background-color: $green;
  }
}