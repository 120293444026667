@import "@sass/colors.scss";

%submit_button  {
    width: 100% !important ;
    height: 65px !important;
    font-size: 22px !important;
    font-weight: 600 !important;
    border-radius: 8px !important;
    border: none !important;
}
.container {
    padding: 40px;
}
.form_row {
    display: flex;
    flex-wrap: nowrap !important;
}
.form_field {
    width: 50%;
    margin-right: 1rem;
}

.typography_title {
    min-height: 0px !important;
    margin-bottom: 0px !important;
}

.form_field:nth-child(2n) {
    margin-right: 0;
}

.submit_button {
    @extend %submit_button;
    background-color: $green !important;
    color: white !important;
}

.card {
    width: 100%;
    border-radius: 16px !important;
    margin-bottom: 40px !important;
}
.disabled_button {
    @extend %submit_button;
    background-color: rgb(216, 218, 218) !important;
    color: rgb(135, 135, 135) !important;
    cursor: not-allowed;
}
.input_site {
    & + label span {
        font-size: 14px !important;
    }
}
