@import "@sass/colors.scss";

%text_style {
    font-weight: 500;
}
.container {
    padding: 20px 40px;
}
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
}

.editIcon {
    margin-right: 20px;
    font-size: 24px;
    color: $green !important;
}

.deleteIcon {
    font-size: 24px;
    color: $button-c-red !important;
}

.filterRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
}

.EditRow {
    display: flex;
}

.trashIcon {
    font-size: 20px;
    color: red !important;
    cursor: pointer;
    margin-right: 20px;
}

.checkbox {
    font-size: 25px !important;
    color: $green !important;
}

.active {
    @extend %text_style;
    color: $green;
    margin-left: -5px;
}

.inactive {
    @extend %text_style;
    color: $button-c-red;
    margin-left: -12px;
}
.status {
    margin-left: 23px;
}
