.filterRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
}
.ymaps-2-1-79-gotoymaps__container,
.ymaps-2-1-79-gototech {
    display: none !important;
}
.map {
    display: flex;
    flex-wrap: nowrap !important;
    width: 100%;
    height: 500px;
    margin-bottom: 48px;
}
