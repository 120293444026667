@import "@sass/colors.scss";

%submit_button {
    width: 100% !important ;
    height: 65px !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    border-radius: 8px !important;
    border: none !important;
}
.button {
    @extend %submit_button;
    background-color: $green !important;
    color: white !important;
}
.text {
    font-size: 22px;
    text-align: center;
}
.disable_button {
    @extend %submit_button;
    background-color: rgb(216, 218, 218) !important;
    color: rgb(135, 135, 135) !important;
    cursor: not-allowed !important;
}
