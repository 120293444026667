@import "@sass/colors.scss";
.buttons_container {
    display: flex;
    border-radius: 5px;
    margin-bottom: 24px;
    background-color: rgb(236, 234, 234);
    flex-wrap: nowrap !important;
}

.group_buttons {
    width: 50%;
    margin-right: 1rem !important;
}

.group_buttons:nth-child(2) {
    margin-right: 0 !important;
}

.buttons_flex_container {
    display: flex;
}
.buttons_flex_container:nth-child(2) {
    margin-right: 0;
}
.container {
    margin-bottom: 24px;
    display: flex;
    min-height: 65px !important;
}

.button_element {
    width: 50%;
    font-size: 24px;
    padding-left: 10px;
}
.button_element:first-child {
    padding-left: 0;
}

.radio_button {
    width: 100%;
    color: rgb(24, 24, 24) !important;
    font-weight: 600;
    border-radius: 8px !important;
}

.radio_button_checked {
    width: 100%;
    color: rgb(255, 255, 255) !important;
    font-weight: 600;
    border-radius: 8px !important;
    border: none !important;
    background-color: $green !important;
}
