@import "@sass/colors.scss";

@font-face {
  font-family: "Avenir";
  src: url("./fonts/AvenirNextCyr-Regular.woff");
}

html,
input,
button,
textarea {
  font-family: "Avenir";
  color: black;
}

body {
  background-color: #e5e5e5;
}

//table

table thead tr th {
  background: white !important;
}

table thead tr th::before {
  width: 0 !important;
}

.ant-table-container table > thead > tr:first-child th:first-child {
  border-top-left-radius: 8px !important;
}

.ant-table-container table > thead > tr:first-child th:last-child {
  border-top-right-radius: 8px !important;
}

.ant-table {
  border-radius: 8px 8px 0 0 !important;
}

.ant-table-tbody > tr > td {
  border-bottom: 0px solid #f0f0f0;
}

//

.ant-menu-item-selected {
  color: $green;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: transparent;
}

.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  border-right: transparent;
}

.ant-menu-light .ant-menu-item:hover,
.ant-menu-light .ant-menu-item-active,
.ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-light .ant-menu-submenu-active,
.ant-menu-light .ant-menu-submenu-title:hover {
  color: $green;
}

.ant-select-arrow {
  color: $green;
  font-size: 14px;
}

//pagination
.anticon-right,
.anticon-left {
  color: $green !important;
  font-size: 14px;
}

.ant-pagination-item-link {
  border: none !important;
  background-color: transparent !important;
}

.ant-pagination-item {
  background-color: transparent !important;
  border: none !important;
  color: black !important;
  font-weight: 600 !important;
}

.ant-pagination-item-active {
  background-color: $green !important;
  color: white !important;
  border-radius: 6px !important;

  a {
    color: white !important;
  }
}

.ant-pagination-options {
  display: none !important;
}

//modal
.ant-modal-content {
  border-radius: 16px;
}

//checkbox
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: $green;
  border-color: $green;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: $green;
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: $green;
}

//sort icon

.anticon .anticon-caret-up .ant-table-column-sorter-up, .active {
  color: $green !important;
}

.anticon .anticon-caret-down .ant-table-column-sorter-down, .active {
  color: $green !important;
}

//btns
.ant-btn-primary {
  background: $green !important;
  border: 1px solid $green;
  //background-color: $green !important;
  &:hover {
    box-shadow: 0 0 0 1px $green;
  }
}

.ant-btn-link {
  color: $green;

  &:hover {
    box-shadow: 0 0 0 1px $green;
  }
}

.ant-picker-focused {
  border-color: #1f9643e5 !important;
  box-shadow: #1beb4233 !important;
}
